
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function fitmentTableTabMobileRT () {
    return this.vehicleSpecific || this.manyFitments ? _createElement('div', {
        'className': 'cm_fitment-table_tab__mobile',
        'key': '0'
    }, _createElement('div', { 'className': 'heading-sm' }, 'Fitments'), _createElement('div', { 'className': 'chevron' }, _createElement('svg', {
        'aria-hidden': 'true',
        'focusable': 'false',
        'role': 'presentation',
        'className': 'icon icon-caret',
        'viewBox': '0 0 10 6'
    }, _createElement('path', {
        'fillRule': 'evenodd',
        'clipRule': 'evenodd',
        'd': 'M9.354.646a.5.5 0 00-.708 0L5 4.293 1.354.646a.5.5 0 00-.708.708l4 4a.5.5 0 00.708 0l4-4a.5.5 0 000-.708z',
        'fill': 'currentColor'
    })))) : null;
}
        export const componentNames = []