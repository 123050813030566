
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogButtonMobileRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog_open-button' }, [this.searchBoxDialogButton(function () {
            return _createElement('div', { 'className': 'cm_desktop-hide  cm_searchBoxDialogButton' }, _createElement('svg', {
                'className': 'icon icon-search',
                'xmlns': 'http://www.w3.org/2000/svg',
                'width': '19.134',
                'height': '19.134',
                'viewBox': '0 0 19.134 19.134'
            }, _createElement('g', { 'transform': 'translate(-3.75 -3.75)' }, _createElement('path', {
                'd': 'M19.9,12.2a7.7,7.7,0,1,1-7.7-7.7A7.7,7.7,0,0,1,19.9,12.2Z',
                'fill': 'none',
                'stroke': '#000',
                'strokeLinecap': 'round',
                'strokeLinejoin': 'round',
                'strokeWidth': '1.5'
            }), _createElement('path', {
                'd': 'M29.162,29.162l-4.187-4.187',
                'transform': 'translate(-7.338 -7.338)',
                'fill': 'none',
                'stroke': '#000',
                'strokeLinecap': 'round',
                'strokeLinejoin': 'round',
                'strokeWidth': '1.5'
            }))));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:searchBoxDialogButton"]