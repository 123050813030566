export default {
  BUTTON_GO_CLASS: 'button-1',

  GARAGE_TITLE: '',

  SELECT_YOUR_VEHICLE_BLOCK: 'Select your vehicle',
  SELECT_YOUR_VEHICLE: '<rt-virtual template="icons/garage" /> Select Your Vehicle',
  ADD_VEHICLE_BUTTON_CLASS: 'button-1',
  GO_TEXT: 'Submit',
  CLEAR_TEXT: 'Reset',
  REMOVE_VEHICLE_ICON: '<rt-virtual template="icons/remove-vehicle" />',

  VERIFY_FITMENT_VEHICLE: `<b>Your selected vehicle:</b> {{ vehicleString || 'Vehicle' }}`,
  VERIFY_FITMENT_FITS: 'This product will fit your selected vehicle',

  LOAD_MORE_BUTTON_CLASS: 'button-1',
  LOAD_MORE_TEXT: 'Load more',

  FACET_DIALOG_CLEAR: 'Reset Filters',
  FACET_DIALOG_DONE: 'Apply',
};
