const baseFitmentFields = globalThis.Convermax.config?.baseFitmentFields || ['Year', 'Make', 'Model'];
const extraFitmentFields = globalThis.Convermax.config?.extraFitmentFields || ['Submodel'];
const wheelsFields = globalThis.Convermax.config?.wheelsFields || [
  'wheel_diameter',
  'wheel_width',
  'wheel_bolt_pattern',
];
const tiresFields = globalThis.Convermax.config?.tiresFields || ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields];

const isVehicleLanding = !!globalThis.Convermax.config?.isVehicleLanding;
const vehicleLandingPageView = globalThis.Convermax.config?.vehicleLandingPageView ?? 'products';

const { customerCompanyId } = globalThis.Convermax;

// if the theme has a currency selector for markets, the URL should contain a culture code based on the selected currency
const cultureCode = globalThis.Shopify?.routes?.root.slice(0, -1) ?? '';
const searchPageUrl = `${cultureCode}/pages/search`;

const getStoreCurrency = () => globalThis.Shopify.currency?.active;

const handleCurrencies = (selector) => {
  if (window.Currency) {
    // window.Currency.cookie.read() can cause exception "Uncaught TypeError: jQuery.cookie is not a function"
    try {
      const currentCurrency = window.Currency?.cookie.read();
      window.Currency.convertAll(getStoreCurrency(), currentCurrency, selector);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.debug(e.message);
    }
  }
};

const fieldsForRelated = ['category', ...wheelsFields, ...tiresFields];
function getSelection(itemData) {
  return fieldsForRelated.flatMap((field) => {
    const value = itemData[field];
    return !value
      ? []
      : Array.isArray(value)
        ? value.map((term) => ({ field, term }))
        : { field, term: value };
  });
}

const updateCallback = () => {
  handleCurrencies('.cm_SearchResult .cm_product-item__price');

  if (window._affirm_config) {
    window.affirm.ui.ready(function () {
      window.affirm.ui.refresh();
    });
  }
};

export default {
  platform: 'shopify',
  storeId: window.Shopify?.shop?.replace('.myshopify.com', ''),
  searchPageUrl,
  SearchRequestDefaults: {
    pageSize: globalThis.Convermax.config?.pageSize || 36,
    extra: customerCompanyId ? { customerCompanyId } : {},
    sort: window.Convermax.config?.defaultSort ?? 'relevance',
  },
  autocomplete: { requestDefaults: { extra: customerCompanyId ? { customerCompanyId } : {} } },
  product: {
    searchResultsImageWidth: 380,
    searchResultsImageHeight: 380,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: '/collections', field: window.Convermax.config?.categorySelectionPageField ?? 'category' },
      {
        pathname: window.document.querySelector('#cm-brand-list') ? window.location.pathname : null,
        field: 'vendor',
      },
      {
        pathname:
          isVehicleLanding && vehicleLandingPageView === 'categories' ? window.location.pathname : null,
        field: 'category',
      },
    ],
    categorySelectionPageRedirect: !!window.Convermax.config?.categorySelectionPageRedirect,
    doNotSaveSelectedVehicle: !!window.Convermax.config?.doNotSaveSelectedVehicle,
    expectResponseRedirect: true,
    forceVehicleSelection: !!window.Convermax.config?.forceVehicleSelection,
    isAutoRedirectDisabled: !!window.Convermax.config?.isAutoRedirectDisabled,
    isAutoVehicleSelectionDisabled: !!window.Convermax.config?.isAutoVehicleSelectionDisabled,
    isVehicleSelectionIsolated: !!window.Convermax.config?.isVehicleSelectionIsolated,
    hideVehicleFromUrl: !!window.Convermax.config?.hideVehicleFromUrl,
    vehicleDataExpires: window.Convermax.config?.vehicleDataExpires,
    onVerifyFitmentInitResponseReceived: (response) => {
      const fits = response.Items?.[0]?.Fitment;
      const verifyFitmentContainer = window.document.querySelector('#main-product .cm_verify-container');

      if (verifyFitmentContainer && fits === 'Vehicle Specific') {
        verifyFitmentContainer.classList.remove('cm_hide');
      }
    },
  },
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } }],
    toggleFacet: [{ fields: ['stock'], term: 'All' }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '#cm-collection-page',
      template: 'SearchPage',
      visibleIf: () => !isVehicleLanding || vehicleLandingPageView === 'products',
    },
    {
      name: 'CategoryPageSubcategories',
      type: 'FacetTiles',
      location: '#cm-subcategory-list',
      template: 'facetTiles/subtitles',
      visibleIf: () =>
        (!isVehicleLanding || vehicleLandingPageView === 'products') &&
        window.location.pathname !== '/collections/vendors',
      facetField: 'category',
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'SearchResult',
      infiniteScrollDisabled: !!window.Convermax.config?.infiniteScrollDisabled,
      updateCallback,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
        'discount_percentage:desc': 'Discount % (High To Low)',
      },
    },
    {
      name: 'SearchBox',
      type: 'SearchBoxDialogButton',
      location: '#cm-search-box',
      onDropdownItemsReceived: () => handleCurrencies('.cm_search-box-root div.price'),
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'SearchBoxDialogButton',
      location: '#cm-mobile-search-box',
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'SearchBoxUnknown',
      type: 'SearchBoxDialogButton',
      location: { replace: '.main-404-page form.search-bar-qf', class: 'cm_search-box-root__unknown' },
      template: 'searchBox/dialogButtonAdaptable',
      disableDropdown: true,
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm',
      template: 'fitmentSearch/collapsibleVehicleWidget',
      columnBreakpoint: 570,
      initCollapsed: !!window.Convermax.config?.ymmInitCollapsed,
      fields: !!window.Convermax.config?.extraFieldsAsFilters && baseFitmentFields,
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-home-ymm',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 490,
      fields: !!window.Convermax.config?.extraFieldsAsFilters && baseFitmentFields,
    },
    {
      name: 'HeaderVehicleWidgetStub',
      type: 'VehicleWidget',
      location: '#cm-ymm-stub',
      template: 'fitmentSearch/collapsibleVehicleWidget',
      columnBreakpoint: 650,
      initCollapsed: !!window.Convermax.config?.ymmInitCollapsed,
      fields: !!window.Convermax.config?.extraFieldsAsFilters && baseFitmentFields,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      fields: !!window.Convermax.config?.extraFieldsAsFilters && baseFitmentFields,
    },
    {
      name: 'VehicleSpecs',
      type: 'VehicleWidget',
      fields: !!window.Convermax.config?.extraFieldsAsFilters && extraFitmentFields,
      visibleIf: () => !!window.Convermax.config?.extraFieldsAsFilters,
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'Garage',
      location: '#cm-garage',
      template: 'fitmentSearch/garage',
      useVehicleWidget: true,
    },
    {
      name: 'MobileGarage',
      type: 'Garage',
      location: '#cm-mobile-garage',
      template: 'fitmentSearch/garage',
      useVehicleWidget: true,
    },
    {
      name: 'HomeVehicleWidgetGarage',
      type: 'Garage',
      location: {
        selector: '#cm-home-garage',
        class: 'cm_garage__home',
      },
      template: 'fitmentSearch/homeGarage',
    },
    {
      name: 'HeaderVehicleWidgetGarage',
      type: 'Garage',
      location: {
        selector: '#cm-ymm-garage',
        class: 'cm_garage__ymm',
      },
      template: 'fitmentSearch/vehicleGarage',
      doNotRedirectOnVehicleSelect: true,
    },
    {
      name: 'VerfiyGarage',
      type: 'Garage',
      location: {
        selector: '#main-product .product-form .cm_verify-container #cm-verify-garage',
        class: 'cm_garage__verify',
      },
      template: 'fitmentSearch/homeGarage',
      doNotRedirectOnVehicleSelect: true,
    },
    {
      name: 'SingleVehicleGarage',
      location: { selector: '#cm-single-garage', class: 'cm-single-garage' },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'MobileSingleVehicleGarage',
      type: 'SingleVehicleGarage',
      location: {
        lastChildOf: '.mobile-menu .mobile-menu-navigation',
        class: 'cm-single-garage-mobile link bg-color',
      },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: '#main-product .product-form .cm_verify-container #cm-verify-fitment',
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTable',
      location: '#cm-fitment-table',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FitmentTableMobile',
      type: 'FitmentTable',
      location: '#cm-fitment-table-mobile',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: '#cm-fitment-table-tab',
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FitmentTableTabMobile',
      type: 'FitmentTable',
      location: '#cm-fitment-table-tab-mobile',
      template: 'fitmentSearch/fitmentTableTabMobile',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '#cm-collection-list',
      template: 'facetTiles/hierarchicalContainer',
      isMultiLevel: !!window.Convermax.config?.categoriesIsMultiLevel,
      isVehicleWidgetDisabled: !!window.document.querySelector('#cm-ymm'),
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm-brand-list',
      template: 'facetTiles/alphabeticalContainer',
      view: 'grid',
      isVehicleWidgetDisabled: !!window.document.querySelector('#cm-ymm'),
    },
    {
      name: 'VehicleLandingPageCategoryTiles',
      type: 'FacetTiles',
      location: '#cm-collection-page',
      template: 'facetTiles/hierarchicalContainer',
      isMultiLevel: !!window.Convermax.config?.categoriesIsMultiLevel,
      visibleIf: () => isVehicleLanding && vehicleLandingPageView === 'categories',
    },
    {
      name: 'RelatedItems',
      location: '#cm-related-products',
      template: 'product/relatedItems',
      count: window.Convermax.config?.relatedItemsCount || 6,
      getSelection,
    },
    {
      name: 'FacetDialog',
      template: 'CustomFacetDialog',
      initCollapsed: window.Convermax.config?.facetDialogInitCollapsed ?? true,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
      onDropdownItemsReceived: () => handleCurrencies('.cm_search-box-root__dialog div.price'),
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
