
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function scopeOptionArrImageVariantId1(formattedTitle, formattedPrice, option, optionIndex) {
                    var optionArr = option.split(';');
                    var image = optionArr[1];
                    var variantId = optionArr[2];
                    return [_createElement('a', {
                            'href': this.url + '?variant=' + variantId,
                            'className': 'colour-option',
                            'key': '30511'
                        }, _createElement('img', {
                            'src': image,
                            'alt': 'Image for ' + formattedTitle,
                            'width': '50',
                            'height': '50',
                            'loading': 'lazy',
                            'decoding': 'async'
                        }))];
                }
                function repeatOption2(formattedTitle, formattedPrice, option, optionIndex, optionArr, image, variantId) {
                    return scopeOptionArrImageVariantId1.apply(this, [
                        formattedTitle,
                        formattedPrice,
                        option,
                        optionIndex
                    ]);
                }
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatI4(formattedTitle, formattedPrice, i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                function repeatI5(formattedTitle, formattedPrice, i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                function scopeVariantArrIdNameStock6(formattedTitle, formattedPrice, variant, variantIndex) {
                    var variantArr = variant.split(';');
                    var id = variantArr[0];
                    var name = variantArr[1];
                    var stock = variantArr[2];
                    return [_createElement('option', {
                            'value': id,
                            'data-inventory-quantity': stock,
                            'key': '94061'
                        }, name)];
                }
                function repeatVariant7(formattedTitle, formattedPrice, variant, variantIndex, variantArr, id, name, stock) {
                    return scopeVariantArrIdNameStock6.apply(this, [
                        formattedTitle,
                        formattedPrice,
                        variant,
                        variantIndex
                    ]);
                }
                function scopeOptionArrOptionImage8(formattedTitle, formattedPrice, position, name, options, optionsIndex) {
                    var optionArr = options.split(';');
                    var option = optionArr[0];
                    var image = optionArr[1];
                    return [
                        _createElement('input', {
                            'className': 'radio-option',
                            'type': 'radio',
                            'name': 'var' + position + 'value',
                            'value': option,
                            'id': this.id + '-template--cm_results-' + option + position,
                            'data-option-position': position,
                            'key': '116401'
                        }),
                        _createElement('label', {
                            'className': 'radio-label',
                            'htmlFor': this.id + '-template--cm_results-' + option + position,
                            'key': '116403'
                        }, _createElement('img', {
                            'src': image,
                            'alt': option,
                            'width': '50',
                            'height': '50',
                            'loading': 'eager',
                            'fetchpriority': 'high'
                        }))
                    ];
                }
                function repeatOptions9(formattedTitle, formattedPrice, position, name, options, optionsIndex, optionArr, option, image) {
                    return scopeOptionArrOptionImage8.apply(this, [
                        formattedTitle,
                        formattedPrice,
                        position,
                        name,
                        options,
                        optionsIndex
                    ]);
                }
                function repeatOption10(formattedTitle, formattedPrice, position, name, options, option, optionIndex) {
                    return [
                        _createElement('input', {
                            'className': 'radio-option',
                            'type': 'radio',
                            'name': 'var' + position + 'value',
                            'value': option,
                            'id': this.id + '-template--cm_results-' + option + position,
                            'data-option-position': position,
                            'key': '125831'
                        }),
                        _createElement('label', {
                            'className': 'radio-label',
                            'htmlFor': this.id + '-template--cm_results-' + option + position,
                            'key': '125833'
                        }, option)
                    ];
                }
                function scopePositionNameOptions11(formattedTitle, formattedPrice) {
                    var position = 1;
                    var name = this.product_data_webdropdown1label;
                    var options = this.option_var1value_colour;
                    return [_createElement('div', {
                            'className': 'product-option-wrapper var' + position + 'value-option' + (name === 'Colour' && options[0].split(';').length === 3 ? ' colour' : ''),
                            'data-option': name,
                            'key': '108930'
                        }, _createElement('div', { 'className': 'option-heading-wrapper' }, _createElement('div', { 'className': 'option-heading' }, name, ':'), _createElement('div', {
                            'className': 'option-name',
                            'data-selected-option': 'Choose an Option',
                            'style': { display: 'none' }
                        }, '\n      Choose an Option\n    ')), name === 'Colour' && options[0].split(';').length === 3 ? _createElement.apply(this, [
                            'div',
                            {
                                'className': 'option-radios',
                                'data-option-position': position,
                                'key': '11482'
                            },
                            _map(options, repeatOptions9.bind(this, formattedTitle, formattedPrice, position, name))
                        ]) : null, this.name !== 'Colour' && options[0].split(';').length !== 3 ? _createElement('div', {
                            'className': 'custom-select-wrapper',
                            'key': '12204'
                        }, _createElement('div', { 'className': 'select-output' }, 'Choose an Option'), _createElement('svg', { 'viewBox': '0 0 24 24' }, _createElement('path', { 'd': 'M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z' })), _createElement.apply(this, [
                            'div',
                            {
                                'className': 'option-radios',
                                'data-option-position': position
                            },
                            _map(options, repeatOption10.bind(this, formattedTitle, formattedPrice, position, name, options))
                        ])) : null)];
                }
                function scopeOptionArrOptionImage12(formattedTitle, formattedPrice, position, name, options, optionsIndex) {
                    var optionArr = options.split(';');
                    var option = optionArr[0];
                    var image = optionArr[1];
                    return [
                        _createElement('input', {
                            'className': 'radio-option',
                            'type': 'radio',
                            'name': 'var' + position + 'value',
                            'value': option,
                            'id': this.id + '-template--cm_results-' + option + position,
                            'data-option-position': position,
                            'key': '138301'
                        }),
                        _createElement('label', {
                            'className': 'radio-label',
                            'htmlFor': this.id + '-template--cm_results-' + option + position,
                            'key': '138303'
                        }, _createElement('img', {
                            'src': image,
                            'alt': option,
                            'width': '50',
                            'height': '50',
                            'loading': 'eager',
                            'fetchpriority': 'high'
                        }))
                    ];
                }
                function repeatOptions13(formattedTitle, formattedPrice, position, name, options, optionsIndex, optionArr, option, image) {
                    return scopeOptionArrOptionImage12.apply(this, [
                        formattedTitle,
                        formattedPrice,
                        position,
                        name,
                        options,
                        optionsIndex
                    ]);
                }
                function repeatOption14(formattedTitle, formattedPrice, position, name, options, option, optionIndex) {
                    return [
                        _createElement('input', {
                            'className': 'radio-option',
                            'type': 'radio',
                            'name': 'var' + position + 'value',
                            'value': option,
                            'id': this.id + '-template--cm_results-' + option + position,
                            'data-option-position': position,
                            'key': '147731'
                        }),
                        _createElement('label', {
                            'className': 'radio-label',
                            'htmlFor': this.id + '-template--cm_results-' + option + position,
                            'key': '147733'
                        }, option)
                    ];
                }
                function scopePositionNameOptions15(formattedTitle, formattedPrice) {
                    var position = 1;
                    var name = this.product_data_webdropdown1label;
                    var options = this.option_var1value;
                    return [_createElement('div', {
                            'className': 'product-option-wrapper var' + position + 'value-option' + (name === 'Colour' && options[0].split(';').length === 3 ? ' colour' : ''),
                            'data-option': name,
                            'key': '130560'
                        }, _createElement('div', { 'className': 'option-heading-wrapper' }, _createElement('div', { 'className': 'option-heading' }, name, ':'), _createElement('div', {
                            'className': 'option-name',
                            'data-selected-option': 'Choose an Option',
                            'style': { display: 'none' }
                        }, '\n      Choose an Option\n    ')), name === 'Colour' && options[0].split(';').length === 3 ? _createElement.apply(this, [
                            'div',
                            {
                                'className': 'option-radios',
                                'data-option-position': position,
                                'key': '13672'
                            },
                            _map(options, repeatOptions13.bind(this, formattedTitle, formattedPrice, position, name))
                        ]) : null, this.name !== 'Colour' && options[0].split(';').length !== 3 ? _createElement('div', {
                            'className': 'custom-select-wrapper',
                            'key': '14394'
                        }, _createElement('div', { 'className': 'select-output' }, 'Choose an Option'), _createElement('svg', { 'viewBox': '0 0 24 24' }, _createElement('path', { 'd': 'M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z' })), _createElement.apply(this, [
                            'div',
                            {
                                'className': 'option-radios',
                                'data-option-position': position
                            },
                            _map(options, repeatOption14.bind(this, formattedTitle, formattedPrice, position, name, options))
                        ])) : null)];
                }
                function scopeOptionArrOptionImage16(formattedTitle, formattedPrice, position, name, options, optionsIndex) {
                    var optionArr = options.split(';');
                    var option = optionArr[0];
                    var image = optionArr[1];
                    return [
                        _createElement('input', {
                            'className': 'radio-option',
                            'type': 'radio',
                            'name': 'var' + position + 'value',
                            'value': option,
                            'id': this.id + '-template--cm_results-' + option + position,
                            'data-option-position': position,
                            'key': '159111'
                        }),
                        _createElement('label', {
                            'className': 'radio-label',
                            'htmlFor': this.id + '-template--cm_results-' + option + position,
                            'key': '159113'
                        }, _createElement('img', {
                            'src': image,
                            'alt': option,
                            'width': '50',
                            'height': '50',
                            'loading': 'eager',
                            'fetchpriority': 'high'
                        }))
                    ];
                }
                function repeatOptions17(formattedTitle, formattedPrice, position, name, options, optionsIndex, optionArr, option, image) {
                    return scopeOptionArrOptionImage16.apply(this, [
                        formattedTitle,
                        formattedPrice,
                        position,
                        name,
                        options,
                        optionsIndex
                    ]);
                }
                function repeatOption18(formattedTitle, formattedPrice, position, name, options, option, optionIndex) {
                    return [
                        _createElement('input', {
                            'className': 'radio-option',
                            'type': 'radio',
                            'name': 'var' + position + 'value',
                            'value': option,
                            'id': this.id + '-template--cm_results-' + option + position,
                            'data-option-position': position,
                            'key': '168541'
                        }),
                        _createElement('label', {
                            'className': 'radio-label',
                            'htmlFor': this.id + '-template--cm_results-' + option + position,
                            'key': '168543'
                        }, option)
                    ];
                }
                function scopePositionNameOptions19(formattedTitle, formattedPrice) {
                    var position = 2;
                    var name = this.product_data_webdropdown2label;
                    var options = this.option_var2value;
                    return [_createElement('div', {
                            'className': 'product-option-wrapper var' + position + 'value-option' + (name === 'Colour' && options[0].split(';').length === 3 ? ' colour' : ''),
                            'data-option': name,
                            'key': '151780'
                        }, _createElement('div', { 'className': 'option-heading-wrapper' }, _createElement('div', { 'className': 'option-heading' }, name, ':'), _createElement('div', {
                            'className': 'option-name',
                            'data-selected-option': 'Choose an Option',
                            'style': { display: 'none' }
                        }, '\n      Choose an Option\n    ')), name === 'Colour' && options[0].split(';').length === 3 ? _createElement.apply(this, [
                            'div',
                            {
                                'className': 'option-radios',
                                'data-option-position': position,
                                'key': '15753'
                            },
                            _map(options, repeatOptions17.bind(this, formattedTitle, formattedPrice, position, name))
                        ]) : null, this.name !== 'Colour' && options[0].split(';').length !== 3 ? _createElement('div', {
                            'className': 'custom-select-wrapper',
                            'key': '16475'
                        }, _createElement('div', { 'className': 'select-output' }, 'Choose an Option'), _createElement('svg', { 'viewBox': '0 0 24 24' }, _createElement('path', { 'd': 'M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z' })), _createElement.apply(this, [
                            'div',
                            {
                                'className': 'option-radios',
                                'data-option-position': position
                            },
                            _map(options, repeatOption18.bind(this, formattedTitle, formattedPrice, position, name, options))
                        ])) : null)];
                }
                function scopeOptionArrOptionImage20(formattedTitle, formattedPrice, position, name, options, optionsIndex) {
                    var optionArr = options.split(';');
                    var option = optionArr[0];
                    var image = optionArr[1];
                    return [
                        _createElement('input', {
                            'className': 'radio-option',
                            'type': 'radio',
                            'name': 'var' + position + 'value',
                            'value': option,
                            'id': this.id + '-template--cm_results-' + option + position,
                            'data-option-position': position,
                            'key': '179921'
                        }),
                        _createElement('label', {
                            'className': 'radio-label',
                            'htmlFor': this.id + '-template--cm_results-' + option + position,
                            'key': '179923'
                        }, _createElement('img', {
                            'src': image,
                            'alt': option,
                            'width': '50',
                            'height': '50',
                            'loading': 'eager',
                            'fetchpriority': 'high'
                        }))
                    ];
                }
                function repeatOptions21(formattedTitle, formattedPrice, position, name, options, optionsIndex, optionArr, option, image) {
                    return scopeOptionArrOptionImage20.apply(this, [
                        formattedTitle,
                        formattedPrice,
                        position,
                        name,
                        options,
                        optionsIndex
                    ]);
                }
                function repeatOption22(formattedTitle, formattedPrice, position, name, options, option, optionIndex) {
                    return [
                        _createElement('input', {
                            'className': 'radio-option',
                            'type': 'radio',
                            'name': 'var' + position + 'value',
                            'value': option,
                            'id': this.id + '-template--cm_results-' + option + position,
                            'data-option-position': position,
                            'key': '189351'
                        }),
                        _createElement('label', {
                            'className': 'radio-label',
                            'htmlFor': this.id + '-template--cm_results-' + option + position,
                            'key': '189353'
                        }, option)
                    ];
                }
                function scopePositionNameOptions23(formattedTitle, formattedPrice) {
                    var position = 3;
                    var name = this.product_data_webdropdown3label;
                    var options = this.option_var3value;
                    return [_createElement('div', {
                            'className': 'product-option-wrapper var' + position + 'value-option' + (name === 'Colour' && options[0].split(';').length === 3 ? ' colour' : ''),
                            'data-option': name,
                            'key': '172590'
                        }, _createElement('div', { 'className': 'option-heading-wrapper' }, _createElement('div', { 'className': 'option-heading' }, name, ':'), _createElement('div', {
                            'className': 'option-name',
                            'data-selected-option': 'Choose an Option',
                            'style': { display: 'none' }
                        }, '\n      Choose an Option\n    ')), name === 'Colour' && options[0].split(';').length === 3 ? _createElement.apply(this, [
                            'div',
                            {
                                'className': 'option-radios',
                                'data-option-position': position,
                                'key': '17834'
                            },
                            _map(options, repeatOptions21.bind(this, formattedTitle, formattedPrice, position, name))
                        ]) : null, this.name !== 'Colour' && options[0].split(';').length !== 3 ? _createElement('div', {
                            'className': 'custom-select-wrapper',
                            'key': '18556'
                        }, _createElement('div', { 'className': 'select-output' }, 'Choose an Option'), _createElement('svg', { 'viewBox': '0 0 24 24' }, _createElement('path', { 'd': 'M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z' })), _createElement.apply(this, [
                            'div',
                            {
                                'className': 'option-radios',
                                'data-option-position': position
                            },
                            _map(options, repeatOption22.bind(this, formattedTitle, formattedPrice, position, name, options))
                        ])) : null)];
                }
                function scopeFormattedTitleFormattedPrice24() {
                    var formattedTitle = this.removeHTML(this.title);
                    var formattedPrice = this.formatPrice(this.price);
                    return _createElement('div', { 'className': 'product-card-container' }, _createElement('div', {
                        'product-card': 'true',
                        'className': 'product-card',
                        'data-product-id': this.id,
                        'data-test': 'true',
                        'data-cart-link-text': 'View'
                    }, _createElement('div', {}, _createElement('a', {
                        'href': this.url,
                        'className': 'product-image',
                        'atia-label': 'Link to ' + formattedTitle
                    }, _createElement('img', {
                        'src': this.imageOrDefault(this.resizeImage(this.image)),
                        'alt': formattedTitle,
                        'onError': this.onImageError,
                        'width': '400',
                        'height': '400',
                        'loading': 'eager',
                        'fetchpriority': 'high'
                    }), this.image2 ? _createElement('img', {
                        'src': this.resizeImage(this.image2),
                        'alt': formattedTitle,
                        'onError': e => this.onImageError(e, 'image2'),
                        'width': '400',
                        'height': '400',
                        'loading': 'eager',
                        'fetchpriority': 'high',
                        'className': 'image-hover',
                        'key': '558'
                    }) : null, _createElement('div', { 'className': 'wishlist-heart' }, _createElement('button', {
                        'type': 'button',
                        'aria-label': 'Add to wishlist',
                        'className': 'button-wishlist',
                        'button-wishlist': 'true',
                        'data-product-handle': this.handle,
                        'data-product-id': this.id
                    }, _createElement('svg', {
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'width': '33',
                        'height': '28.88',
                        'viewBox': '0 0 23.023 21.252'
                    }, _createElement('path', {
                        'id': 'Icon_ionic-md-heart-empty',
                        'data-name': 'Icon ionic-md-heart-empty',
                        'd': 'M20.067,4.5a6.81,6.81,0,0,0-5.18,2.43A6.81,6.81,0,0,0,9.706,4.5a6.259,6.259,0,0,0-6.331,6.37c0,4.4,3.913,7.931,9.84,13.377l1.671,1.505,1.671-1.505C22.485,18.8,26.4,15.27,26.4,10.87A6.259,6.259,0,0,0,20.067,4.5ZM15.595,22.725l-.232.216-.476.432-.476-.432-.232-.216a70.345,70.345,0,0,1-6.791-6.752c-1.55-1.92-2.236-3.492-2.236-5.1A4.6,4.6,0,0,1,6.463,7.588,4.5,4.5,0,0,1,9.706,6.271a5.076,5.076,0,0,1,3.824,1.8l1.356,1.61,1.356-1.61a5.057,5.057,0,0,1,3.824-1.8,4.526,4.526,0,0,1,3.249,1.317,4.613,4.613,0,0,1,1.312,3.282c0,1.6-.692,3.182-2.236,5.1A70.487,70.487,0,0,1,15.595,22.725Z',
                        'transform': 'translate(-3.375 -4.5)'
                    })))), _createElement('div', { 'className': 'product-badges' }, this.on_sale && this.price && this.compare_at_price ? _createElement('div', {
                        'className': 'product-badge top-left rectangle cm_sale-badge',
                        'key': '1918'
                    }, _createElement('div', { 'className': 'badge-image' }, _createElement('svg', {
                        'className': 'badge-image-icon',
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'width': '18.097',
                        'height': '18.097',
                        'viewBox': '0 0 18.097 18.097'
                    }, _createElement('path', {
                        'd': 'M11.3,3a8.3,8.3,0,1,0,5.532,2.113M7.61,14.986,14.986,7.61m.922,6.454a1.844,1.844,0,1,1-1.844-1.844A1.844,1.844,0,0,1,15.909,14.064ZM10.376,8.532A1.844,1.844,0,1,1,8.532,6.688,1.844,1.844,0,0,1,10.376,8.532Z',
                        'transform': 'translate(-2.25 -2.25)',
                        'fill': 'none',
                        'stroke': '#000',
                        'strokeLinecap': 'round',
                        'strokeLinejoin': 'round',
                        'strokeWidth': '1.5'
                    }))), '\n            ', this.discount_percentage, '% off\n          ') : null, this.has_new_badge ? _createElement('div', {
                        'className': 'product-badge top-left rectangle cm_new-badge',
                        'key': '2702'
                    }, 'New') : null, this.product_badges_top_left ? _createElement('div', Object.assign({}, { 'key': '2808' }, { dangerouslySetInnerHTML: { __html: this.product_badges_top_left } })) : null)), this.option_var1value_colour?.length > 1 ? _createElement.apply(this, [
                        'div',
                        {
                            'className': 'colour-options',
                            'key': '2962'
                        },
                        _map(this.option_var1value_colour.slice(0, 3), repeatOption2.bind(this, formattedTitle, formattedPrice))
                    ]) : null, this.product_badges_bottom ? _createElement('div', mergeProps({
                        'className': 'bottom-badge',
                        'key': '3481'
                    }, { dangerouslySetInnerHTML: { __html: this.product_badges_bottom } })) : null), _createElement('div', { 'className': 'card-bottom' }, _createElement('a', mergeProps({
                        'href': this.url,
                        'className': 'product-title'
                    }, { dangerouslySetInnerHTML: { __html: this.title } })), this.price > 0 ? _createElement('div', {
                        'className': 'product-price',
                        'data-price': this.price * 100,
                        'data-product-id': this.id,
                        'key': '3778'
                    }, !this.on_sale ? _createElement('div', {
                        'className': 'prices',
                        'key': '3900'
                    }, [this.review_count ? _createElement.apply(this, [
                            'div',
                            {
                                'className': 'cm_review-stars',
                                'key': '39530'
                            },
                            _map([
                                0,
                                1,
                                2,
                                3,
                                4
                            ], repeatI4.bind(this, formattedTitle, formattedPrice)),
                            _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                        ]) : null], _createElement('span', {}, formattedPrice, this.price_max ? ` - ${ this.formatPrice(this.price_max) }` : '')) : null, this.on_sale ? [
                        _createElement('div', {
                            'className': 'product-reviews-save',
                            'key': '44921'
                        }, _createElement('div', { 'className': 'reviews' }, [this.review_count ? _createElement.apply(this, [
                                'div',
                                {
                                    'className': 'cm_review-stars',
                                    'key': '46190'
                                },
                                _map([
                                    0,
                                    1,
                                    2,
                                    3,
                                    4
                                ], repeatI5.bind(this, formattedTitle, formattedPrice)),
                                _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                            ]) : null]), _createElement('div', { 'className': 'save' }, !this.price_varies ? [
                            '\n                Save ',
                            this.formatPrice(this.compare_at_price - this.price),
                            '\n              '
                        ] : null, this.price_varies ? [
                            this.saving_min_val === 0 ? [
                                '\n                  Save up to ',
                                this.formatPrice(this.saving_max_val),
                                '\n                '
                            ] : null,
                            this.saving_min_val !== 0 ? [
                                this.saving_min_val === this.saving_max_val ? [
                                    '\n                    Save ',
                                    this.formatPrice(this.saving_min_val),
                                    '\n                  '
                                ] : null,
                                this.saving_min_val !== this.saving_max_val ? [
                                    '\n                    Saving ',
                                    this.formatPrice(this.saving_min_val),
                                    ' - ',
                                    this.formatPrice(this.saving_max_val),
                                    '\n                  '
                                ] : null
                            ] : null
                        ] : null)),
                        _createElement('div', {
                            'className': 'prices',
                            'key': '44923'
                        }, _createElement('span', { 'className': 'cm_product-item__price_compare' }, this.formatPrice(this.compare_at_price)), _createElement('span', { 'className': 'cm_product-item__price_sale' }, '\n              ', formattedPrice, this.price_max ? ` - ${ this.formatPrice(this.price_max) }` : '', '\n            '))
                    ] : null) : null, _createElement('div', { 'className': 'product-buttons' }, _createElement('a', {
                        'href': this.url,
                        'className': 'product-link'
                    }, 'View Product'), _createElement('button', {
                        'className': 'quick-add-trigger button-1',
                        'aria-label': 'Add ' + formattedTitle + ' to the cart',
                        'data-quick-add': true,
                        'data-section-id': true,
                        'data-id': this.variant_ids[0],
                        'data-default-variant': !this.variant_ids_fully,
                        'data-product-handle': this.handle,
                        'data-quantity': '1',
                        'disabled': this.out_of_stock
                    }, !this.out_of_stock ? [_createElement('svg', {
                            'className': 'cm_add-to-cart-icon',
                            'xmlns': 'http://www.w3.org/2000/svg',
                            'width': '19.291',
                            'height': '15.111',
                            'viewBox': '0 0 19.291 15.111',
                            'key': '67880'
                        }, _createElement('g', {
                            'id': 'Group_31340',
                            'data-name': 'Group 31340',
                            'transform': 'translate(-430 -1702.001)'
                        }, _createElement('g', {
                            'id': 'Group_31340-2',
                            'data-name': 'Group 31340',
                            'transform': 'translate(11170.764 19283.449)'
                        }, _createElement('path', {
                            'id': 'Icon_awesome-shopping-cart',
                            'data-name': 'Icon awesome-shopping-cart',
                            'd': 'M15.587,8.893l1.4-6.139a.708.708,0,0,0-.691-.865H4.7L4.428.566A.708.708,0,0,0,3.734,0H.708A.708.708,0,0,0,0,.708v.472a.708.708,0,0,0,.708.708H2.771L4.844,12.025a1.653,1.653,0,1,0,1.979.253H13.01a1.652,1.652,0,1,0,1.877-.307l.163-.716a.708.708,0,0,0-.691-.865H6.437l-.193-.944H14.9A.708.708,0,0,0,15.587,8.893Z',
                            'transform': 'translate(-10740.764 -17581.449)',
                            'fill': '#fcfcfc'
                        })), _createElement('circle', {
                            'id': 'Ellipse_159',
                            'data-name': 'Ellipse 159',
                            'cx': '3.64',
                            'cy': '3.64',
                            'r': '3.64',
                            'transform': 'translate(442.01 1705.915)',
                            'fill': '#db2f29'
                        }), _createElement('path', {
                            'id': 'Icon_awesome-plus',
                            'data-name': 'Icon awesome-plus',
                            'd': 'M4.561,4.18H2.982V2.6a.351.351,0,0,0-.351-.351H2.281A.351.351,0,0,0,1.93,2.6V4.18H.351A.351.351,0,0,0,0,4.531v.351a.351.351,0,0,0,.351.351H1.93V6.811a.351.351,0,0,0,.351.351h.351a.351.351,0,0,0,.351-.351V5.232H4.561a.351.351,0,0,0,.351-.351V4.531A.351.351,0,0,0,4.561,4.18Z',
                            'transform': 'translate(443.306 1704.93)',
                            'fill': '#fff'
                        })))] : null, '\n          ', !this.out_of_stock ? 'Buy now' : 'Sold out', '\n        ')), _createElement('form', {
                        'method': 'post',
                        'action': '/cart/add',
                        'id': 'product_form_' + this.id,
                        'acceptCharset': 'UTF-8',
                        'className': 'product-form product-card-form',
                        'encType': 'multipart/form-data',
                        'data-add-to-cart': 'Add to cart',
                        'data-sold-out': 'Sold out',
                        'data-unavailable': 'Unavailable',
                        'choose_option': 'Choose an Option',
                        'data-please-select': 'Select a',
                        'data-saving': 'Save'
                    }, _createElement('input', {
                        'type': 'hidden',
                        'name': 'form_type',
                        'value': 'product'
                    }), _createElement('input', {
                        'type': 'hidden',
                        'name': 'utf8',
                        'value': '\u2713'
                    }), _createElement('div', { 'className': 'info-content' }, _createElement('div', {
                        'className': 'variant-sku',
                        'data-parent-sku': this.sku,
                        'data-quick-code': 'Quick Code'
                    }, '\n            Quick Code: ', this.sku, '\n          '), _createElement('div', { 'className': 'product-title' }, formattedTitle)), _createElement.apply(this, [
                        'select',
                        {
                            'name': 'id',
                            'id': 'product-master-selector-template--cm_results-' + this.id,
                            'className': 'product-master-selector product-form__variants no-js',
                            'data-product-handle': this.handle
                        },
                        _createElement('option', {
                            'value': 'none',
                            'selected': 'selected'
                        }, 'Choose an Option'),
                        _createElement('option', {
                            'value': this.variant_ids[0],
                            'data-inventory-quantity': this.out_of_stock ? 0 : 1000
                        }, '\n            Default Title\n          '),
                        this.variant_ids_fully ? _map(this.variant_ids_fully, repeatVariant7.bind(this, formattedTitle, formattedPrice)) : null
                    ]), !this.variant_ids_fully ? _createElement('div', {
                        'className': 'product-options',
                        'style': { display: 'none' },
                        'key': '9752'
                    }, _createElement('div', {
                        'className': 'product-option-wrapper title-option',
                        'data-option': 'Title'
                    }, _createElement('div', { 'className': 'option-heading-wrapper' }, _createElement('div', { 'className': 'option-heading' }, 'Title:'), _createElement('div', {
                        'className': 'option-name',
                        'data-selected-option': 'Choose an Option',
                        'style': { display: 'none' }
                    }, '\n                Choose an Option\n              ')), _createElement('div', { 'className': 'custom-select-wrapper' }, _createElement('div', { 'className': 'select-output' }, 'Choose an Option'), _createElement('svg', { 'viewBox': '0 0 24 24' }, _createElement('path', { 'd': 'M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z' })), _createElement('div', {
                        'className': 'option-radios',
                        'data-option-position': '1'
                    }, _createElement('input', {
                        'className': 'radio-option',
                        'type': 'radio',
                        'name': 'Title',
                        'value': 'Default Title',
                        'id': this.id + '-template--cm_results-Default Title1',
                        'data-option-position': '1'
                    }), _createElement('label', {
                        'className': 'radio-label',
                        'htmlFor': this.id + '-template--cm_results-Default Title1'
                    }, 'Default Title'))))) : null, this.option_var1value_colour ? scopePositionNameOptions11.apply(this, [
                        formattedTitle,
                        formattedPrice
                    ]) : null, this.option_var1value ? _createElement('div', {
                        'className': 'product-options',
                        'key': '12986'
                    }, !this.option_var1value_colour && this.option_var1value ? scopePositionNameOptions15.apply(this, [
                        formattedTitle,
                        formattedPrice
                    ]) : null, this.option_var2value ? scopePositionNameOptions19.apply(this, [
                        formattedTitle,
                        formattedPrice
                    ]) : null, this.option_var3value ? scopePositionNameOptions23.apply(this, [
                        formattedTitle,
                        formattedPrice
                    ]) : null) : null, _createElement('div', {
                        'className': 'product-price',
                        'data-list-price-type': 'RRP'
                    }, !this.on_sale ? _createElement('div', {
                        'className': 'prices',
                        'key': '19418'
                    }, _createElement('span', {}, formattedPrice, this.price_max ? ` - ${ this.formatPrice(this.price_max) }` : '')) : null, this.on_sale ? [
                        _createElement('div', {
                            'className': 'product-reviews-save',
                            'key': '196091'
                        }, _createElement('div', { 'className': 'save' }, !this.price_varies ? [
                            '\n                  Save ',
                            this.formatPrice(this.compare_at_price - this.price),
                            '\n                '
                        ] : null, this.price_varies ? [
                            this.saving_min_val === 0 ? [
                                '\n                    Save up to ',
                                this.formatPrice(this.saving_max_val),
                                '\n                  '
                            ] : null,
                            this.saving_min_val !== 0 ? [
                                this.saving_min_val === this.saving_max_val ? [
                                    '\n                      Save ',
                                    this.formatPrice(this.saving_min_val),
                                    '\n                    '
                                ] : null,
                                this.saving_min_val !== this.saving_max_val ? [
                                    '\n                      Saving ',
                                    this.formatPrice(this.saving_min_val),
                                    ' - ',
                                    this.formatPrice(this.saving_max_val),
                                    '\n                    '
                                ] : null
                            ] : null
                        ] : null)),
                        _createElement('div', {
                            'className': 'prices',
                            'key': '196093'
                        }, _createElement('span', { 'className': 'cm_product-item__price_compare' }, this.formatPrice(this.compare_at_price)), _createElement('span', { 'className': 'cm_product-item__price_sale' }, '\n                ', formattedPrice, this.price_max ? ` - ${ this.formatPrice(this.price_max) }` : '', '\n              '))
                    ] : null), _createElement('div', { 'className': 'button-container' }, _createElement('div', { 'className': 'quantity-selector' }, _createElement('div', { 'className': 'quantity-minus' }, _createElement('svg', {
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'aria-hidden': 'true',
                        'focusable': 'false',
                        'role': 'presentation',
                        'className': 'icon icon-minus',
                        'fill': 'none',
                        'viewBox': '0 0 10 2'
                    }, _createElement('path', {
                        'fillRule': 'evenodd',
                        'clipRule': 'evenodd',
                        'd': 'M.5 1C.5.7.7.5 1 .5h8a.5.5 0 110 1H1A.5.5 0 01.5 1z',
                        'fill': 'currentColor'
                    }))), _createElement('input', {
                        'className': 'quantity-input',
                        'type': 'number',
                        'name': 'quantity',
                        'min': '1',
                        'defaultValue': '1'
                    }), _createElement('div', { 'className': 'quantity-plus' }, _createElement('svg', {
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'aria-hidden': 'true',
                        'focusable': 'false',
                        'role': 'presentation',
                        'className': 'icon icon-plus',
                        'fill': 'none',
                        'viewBox': '0 0 10 10'
                    }, _createElement('path', {
                        'fillRule': 'evenodd',
                        'clipRule': 'evenodd',
                        'd': 'M1 4.51a.5.5 0 000 1h3.5l.01 3.5a.5.5 0 001-.01V5.5l3.5-.01a.5.5 0 00-.01-1H5.5L5.49.99a.5.5 0 00-1 .01v3.5l-3.5.01H1z',
                        'fill': 'currentColor'
                    })))), _createElement('button', {
                        'className': 'add-to-cart',
                        'type': 'submit',
                        'value': true
                    }, _createElement('span', { 'className': 'text' }, 'Add to cart'))), _createElement('div', { 'className': 'close-quick-add-wrapper' }, _createElement('a', {
                        'className': 'view-product',
                        'href': this.url
                    }, 'View full product info'), _createElement('button', {
                        'className': 'close-quick-add',
                        'data-close-quick-add': 'true'
                    }, _createElement('svg', {
                        'clipRule': 'evenodd',
                        'fillRule': 'evenodd',
                        'strokeLinejoin': 'round',
                        'strokeMiterlimit': '2',
                        'viewBox': '0 0 24 24',
                        'xmlns': 'http://www.w3.org/2000/svg'
                    }, _createElement('path', {
                        'd': 'm14.523 18.787s4.501-4.505 6.255-6.26c.146-.146.219-.338.219-.53s-.073-.383-.219-.53c-1.753-1.754-6.255-6.258-6.255-6.258-.144-.145-.334-.217-.524-.217-.193 0-.385.074-.532.221-.293.292-.295.766-.004 1.056l4.978 4.978h-14.692c-.414 0-.75.336-.75.75s.336.75.75.75h14.692l-4.979 4.979c-.289.289-.286.762.006 1.054.148.148.341.222.533.222.19 0 .378-.072.522-.215z',
                        'fillRule': 'nonzero'
                    })))), _createElement('input', {
                        'type': 'hidden',
                        'name': 'product-id',
                        'value': this.id
                    }), _createElement('input', {
                        'type': 'hidden',
                        'name': 'section-id',
                        'value': 'template--cm_results'
                    })))));
                }
                return scopeFormattedTitleFormattedPrice24.apply(this, []);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []