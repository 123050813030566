
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function fitmentTableTabRT () {
    return this.vehicleSpecific || this.manyFitments ? _createElement('span', {
        'className': 'cm_fitment-table_tab',
        'key': '0'
    }, 'Fitments') : null;
}
        export const componentNames = []